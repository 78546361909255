import React from "react"
import Layout from "hoc/Layout"
import CoursesList from "../../containers/Courses/CoursesList/CoursesList"

const ListOfCourses = () => {
  const SITE_NAME = "Lista kursów"
  const breadcrumbsData = [
    {
      title: "Strona Główna",
      link: "/",
    },
    {
      title: "Kursy",
      link: "/kursy",
    },
    SITE_NAME,
  ]

  return (
    <Layout
      breadcrumbsData={breadcrumbsData}
      seoTitle={SITE_NAME}
      containerSize={"xl"}
    >
      <CoursesList />
    </Layout>
  )
}

export default ListOfCourses
