import { Grid } from "@mui/material"
import React, { useState } from "react"
import Font18 from "../../../components/Fonts/Font18"
import Font42 from "../../../components/Fonts/Font42"
import Font24 from "../../../components/Fonts/Font24"
import Font16 from "../../../components/Fonts/Font16"
import Button from "../../../components/Buttons/Button"
import { parsePrice } from "../../../shared/parsePrice"
import { StaticImage } from "gatsby-plugin-image"
import { Section } from "./CoursesList.styled"
import { CircularProgress } from "@mui/material"

const CoursesList = () => {
  const courses = [
    {
      title: "Pakiet 5 kursów",
      description: (
        <Font16 sx={{ textAlign: "justify" }}>
          Pakiet wszystkich 5 kursów dla opiekunów osób starszych w promocyjnej
          cenie. W ramach pakietu otrzymujesz dostęp do wszystkich treści video
          oraz 5 certyfikatów z każdego szkolenia. Kup Pakiet naszych kursów i
          stań się{" "}
          <b>
            OPIEKUNKĄ EKSPERTKĄ! Z taką liczbą certyfikatów każdy klient będzie
            marzyć o tym, aby Cię zatrudnić!
          </b>
        </Font16>
      ),
      price: 6999,
      id: "all",
    },
    {
      title: "Pierwsza pomoc",
      description: (
        <Font16 sx={{ textAlign: "justify" }}>
          Kurs pierwszej pomocy w pracy z osobą starszą,{" "}
          <b>
            obejmuje zagadnienia udzielania pierwszej pomocy w przypadku
            zatrzymania krążenia, oparzeń, zadławień, utraty przytomności, ataku
            hiperglikemii i innych.
          </b>{" "}
          Pozwala zdobyć wiedzę z najważniejszych zasad udzielania wsparcia
          osobom potrzebującym.
        </Font16>
      ),
      price: 2999,
      id: "first_help",
      image: (
        <StaticImage
          style={{ position: "absolute", width: "100%", height: "100%" }}
          src="../../../images/courses/pierwsza-pomoc.png"
          placeholder="blurred"
        />
      ),
    },
    {
      title: "Alzheimer",
      description: (
        <Font16 sx={{ textAlign: "justify" }}>
          Kurs opieki nad osobami z Alzheimerem.{" "}
          <b>
            Obejmuje zagadnienia wsparcia psychicznego dla osoby chorej,
            radzenia sobie z agresją podopiecznego, zajęć aktywizujących dla
            osoby chorej, diety oraz higieny
          </b>{" "}
          seniorów chorych na Alzheimera.
        </Font16>
      ),
      price: 2999,
      id: "alzheimer",
      image: (
        <StaticImage
          style={{ position: "absolute", width: "100%", height: "100%" }}
          src="../../../images/courses/alzheimer.png"
          placeholder="blurred"
        />
      ),
    },
    {
      title: "Demencja",
      description: (
        <Font16 sx={{ textAlign: "justify" }}>
          Kurs opieki nad osobami z demencją.{" "}
          <b>
            W kursie poruszane są kwestie komunikacji z osobą chorą, higieny
            osoby chorej na demencję, stadiów choroby, zajęć aktywizujących,
            diagnostyki i spowalniania postępów choroby.
          </b>
        </Font16>
      ),
      price: 2999,
      id: "dementia",
      image: (
        <StaticImage
          style={{ position: "absolute", width: "100%", height: "100%" }}
          src="../../../images/courses/demencja.png"
          placeholder="blurred"
        />
      ),
    },
    {
      title: "Osoby leżące",
      description: (
        <Font16 sx={{ textAlign: "justify" }}>
          Kurs opieki nad pacjentami leżącymi i paliatywnie chorymi.{" "}
          <b>
            Porusza kwestie higieny osób leżących, metod kąpieli w łóżku, zmiany
            pościeli i prześcieradła, karmienia i komunikacji z osobami leżącymi
            oraz transferowania osób chorych na wózek inwalidzki z łóżka i
            odwrotnie.
          </b>
        </Font16>
      ),
      price: 2999,
      id: "lying_persons",
      image: (
        <StaticImage
          style={{ position: "absolute", width: "100%", height: "100%" }}
          src="../../../images/courses/osoby-lezace.png"
          placeholder="blurred"
        />
      ),
    },
    {
      title: "Nowotwory",
      description: (
        <Font16 sx={{ textAlign: "justify" }}>
          Kurs opieki nad osobami z nowotworami. W materiałach znajują się{" "}
          <b>
            treści dotyczące wsparcia psychicznego osób z nowotworami, dbania o
            rany pooperacyjne, żywienia w różnych stadiach choroby nowotworowej,
            zapobiegania wymiotom i nudnościom oraz dbania o skórę pacjenta po
            naświetlaniach.
          </b>
        </Font16>
      ),
      price: 2999,
      id: "tumors",
      image: (
        <StaticImage
          style={{ position: "absolute", width: "100%", height: "100%" }}
          src="../../../images/courses/nowotwory.png"
          placeholder="blurred"
        />
      ),
    },
  ]

  const [isLoading, setIsLoading] = useState(null)
  return (
    <Section>
      <Font42 tt="uppercase" sx={{ textAlign: "center", marginBottom: "60px" }}>
        Wybierz kurs dla siebie
      </Font42>

      {courses.map(({ title, price, description, id, image }) => (
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          gap={"80px"}
          sx={{ marginBottom: "40px", gap: { xs: "0px", lg: "80px" } }}
        >
          <Grid
            item
            // xs={12}
            // sm={6}
            // md={8}
            sx={{
              position: "relative",
              width: { xs: "100%", md: 220 },
              height: 220,
              background:
                "linear-gradient(180deg, #544485 55.21%, rgba(84, 68, 133, 0) 100%)",
            }}
          >
            {image}
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{
                height: "100%",
                padding: "10px",
                zIndex: 1,
                position: "relative",
              }}
            >
              <Font24 color="white" tt="uppercase" sx={{ textAlign: "center" }}>
                {title}
              </Font24>
              <Font18 color="white">{parsePrice(price)}</Font18>
            </Grid>
          </Grid>
          <Grid
            xs={12}
            md={7}
            lg={4}
            sx={{
              backgroundColor: "white",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
            }}
          >
            <Font16 sx={{ textAlign: "justify" }}>{description}</Font16>
            <Button
              sx={{
                marginLeft: "auto",
                textTransform: "uppercase",
                fontWeight: 700,
                fontSize: 18,
                marginTop: "10px",
                minWidth: 150,
                minHeight: 45,
              }}
              disabled={isLoading}
              onClick={() => {
                setIsLoading(id)
                window.location.href = `https://www.checkout.medira.pl/?course=${id}`
              }}
            >
              {isLoading === id ? (
                <CircularProgress size={20} sx={{ color: "#7b7883" }} />
              ) : (
                "Kup teraz"
              )}
            </Button>
          </Grid>
        </Grid>
      ))}
    </Section>
  )
}

export default CoursesList
